import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import { Link } from 'gatsby';
import Image from '../../components/Image';
// import Hero from '../../components/Hero';
// import Icon from '../../components/Icon';

const ScienceAtWork = () => (
  <Layout title="Science at Work" className="science-starters">
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-purple"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="/science-starters/getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb active">Science at Work</li>
        </ul>
        <h1 className="page-title">Science at Work</h1>
        <Image filename="hero-science-at-work.png" className="hero-image" />
      </div>
    </div>
    {/* Getting Started */}
    <Section>
      <Container>
        <Row>
          <Column size={8} offset={2} className="global-intro">
            <h4>
              Discover how 3M Scientists tackle meaningful challenges and
              explore exciting new possibilities that impact the world. Go
              inside a variety of science careers and find out what inspires
              these diverse 3M Scientists using the below profiles.
            </h4>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Employee Highlight */}
    <Section className="mb-4">
      <Container>
        <Row>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-promo-sarah-frisco.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>Sara Frisco</h3>
                <h4 className="question">
                  Can scientific exploration be used to improve people’s lives?
                </h4>
                <p>
                  Go inside the 3M labs with an engineer who taps into a dynamic
                  collaborative network to problem-solve exciting new solutions
                  to real-world problems.
                </p>
                <Link
                  to="../science-at-work/sara-frisco"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-promo-jeff-emslander.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>Jeff Emslander</h3>
                <h4 className="question">
                  What role does new technology play in the work of scientists?
                </h4>
                <p>
                  Learn more about the groundbreaking tech that scientists
                  interact with daily to solve challenges and discover
                  life-changing new solutions.
                </p>
                <Link
                  to="../science-at-work/jeff-emslander"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-promo-tesha-alston-dampier.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>Tesha Alston Dampier</h3>
                <h4 className="question">
                  How does individual passion fuel scientific innovation?
                </h4>
                <p className="mb-2">
                  Meet a 3M Scientist working in the field of biopharmaceuticals
                  who balances her scientific passion with her commitment to
                  empowering others.
                </p>
                <Link
                  to="../science-at-work/tesha-alston-dampier"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default ScienceAtWork;
